import { useAppContext } from '../../../../state/useAppProvider';
import { ConversationState } from '../../../../types';
import { AddProductIcon } from '../AddProductIcon';
import './AddProductButton.scss';

export const AddProductButton = () => {
  const { dispatch } = useAppContext();

  const onAddProductClick = () => {
    dispatch({
      type: 'SET_CONVERSATION_STATE',
      payload: ConversationState.PRODUCT_SELECTION,
    });
  };

  return (
    <button
      onClick={onAddProductClick}
      className='add-product-prompt-button btn-badge'
    >
      <AddProductIcon />
    </button>
  );
};
