export const DocumentIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24px'
      viewBox='0 -960 960 960'
      width='24px'
    >
      <path d='M200-800v253.31-1V-160v-640 160-160Zm120 380h111.54q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24T431.54-460H320q-8.5 0-14.25 5.76T300-439.97q0 8.51 5.75 14.24T320-420Zm0 160h70q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24T390-300h-70q-8.5 0-14.25 5.76T300-279.97q0 8.51 5.75 14.24T320-260Zm-95.38 140q-27.62 0-46.12-18.5Q160-157 160-184.62v-590.76q0-27.62 18.5-46.12Q197-840 224.62-840h288.53q13.27 0 25.29 5.23t20.71 13.92l141.7 141.7q8.69 8.69 13.92 20.71 5.23 12.02 5.23 25.29v80.3q0 8.62-5.76 14.2-5.76 5.57-14.27 5.57t-14.24-5.75q-5.73-5.75-5.73-14.25V-640H551.99q-13.61 0-22.8-9.2Q520-658.4 520-672v-128H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v590.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h208.46q8.5 0 14.25 5.76t5.75 14.27q0 8.51-5.75 14.24T433.08-120H224.62Zm435.53-67.69q47.23 0 79.7-32.62 32.46-32.61 32.46-79.84 0-47.23-32.62-79.7-32.61-32.46-79.84-32.46-47.23 0-79.7 32.62-32.46 32.61-32.46 79.84 0 47.23 32.62 79.7 32.61 32.46 79.84 32.46ZM878.21-81.85q-5.56 5.62-14.16 5.62-8.59 0-14.2-5.62l-96.93-97.69q-19.46 15.54-43.19 23.69-23.73 8.16-49.73 8.16-63.46 0-107.88-44.43-44.43-44.42-44.43-107.88 0-63.46 44.43-107.88 44.42-44.43 107.88-44.43 63.46 0 107.88 44.43 44.43 44.42 44.43 107.88 0 26-8.16 49.73-8.15 23.73-23.69 43.19l97.69 96.93q5.62 5.54 5.62 14.12 0 8.57-5.56 14.18Z' />
    </svg>
  );
};
