import { useEffect, useState } from 'react';
import { useApiContext } from '../../api/useApiContext';
import { VersionInfo } from '../../types';
//import { useSearchParams } from 'react-router-dom';
import './VersionInfo.scss';
import { useIsDevmode } from '../../hooks/use-is-devmode';

export const VersionInfoPanel = () => {
  const { getBackendVersionInfo } = useApiContext();
  const [backendInfo, setBackendinfo] = useState<VersionInfo | null>(null);
  const displayVersionInfo = useIsDevmode();
  const imageTag = import.meta.env.VITE_IMAGE_TAG;

  useEffect(() => {
    getBackendVersionInfo().then((res) => setBackendinfo(res));
  }, [getBackendVersionInfo]);

  if (!displayVersionInfo) return null;

  return (
    <ul className='version-info-container'>
      <li>backend-commit: {backendInfo?.backendVersion}</li>
      <li>backend-tag: {backendInfo?.imageTag}</li>
      <li>frontend-tag: {imageTag}</li>
      <li>model: {backendInfo?.model}</li>
      <li>index: {backendInfo?.index}</li>
    </ul>
  );
};
