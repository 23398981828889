import React, { createContext, useReducer } from 'react';
import { appStateReducer } from './AppReducer';
import { initialState } from './constants';
import { Action, AppState } from '../types';

export const AppStateContext = createContext(
  {} as ReturnType<typeof useProvideAppState>,
);

export const AppStateProvider = ({ ...props }) => {
  const appReducer = useProvideAppState();

  return (
    <AppStateContext.Provider value={appReducer}>
      {props.children}
    </AppStateContext.Provider>
  );
};

const useProvideAppState = (): {
  state: AppState;
  dispatch: React.Dispatch<Action>;
} => {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  return { state, dispatch };
};
