import { useTranslation } from 'react-i18next';
import { useNavigateWithTracker } from '../../hooks/use-navigate-with-tracker';
import { InfoIcon } from './InfoIcon';
import { Pages } from '../../pages/constants/pages';
import './InformationMenu.scss';

export const InformationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithTracker();

  const handleClick = () => {
    const currentPath = window.location.pathname;
    if (currentPath === '/information') {
      navigate(Pages.HOME, '/');
    } else {
      navigate(Pages.INFORMATION, '/information');
    }
  };

  return (
    <div className="information-menu-container">
      <button
        data-metrics-id="InformationMenuButton"
        className="info-button"
        onClick={handleClick}
        aria-label={t('information_menu.toggle_button')}
      >
        <InfoIcon />
      </button>
    </div>
  );
};