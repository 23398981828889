export const ExpandIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='30px'
      viewBox='0 -960 960 960'
      width='30px'
      fill='#000000'
    >
      <path d='M280-280h140q8.5 0 14.25 5.76t5.75 14.27q0 8.51-5.75 14.24T420-240H272.31q-13.73 0-23.02-9.29T240-272.31V-420q0-8.5 5.76-14.25t14.27-5.75q8.51 0 14.24 5.75T280-420v140Zm400-400H540q-8.5 0-14.25-5.76T520-700.03q0-8.51 5.75-14.24T540-720h147.69q13.73 0 23.02 9.29t9.29 23.02V-540q0 8.5-5.76 14.25T699.97-520q-8.51 0-14.24-5.75T680-540v-140Z' />
    </svg>
  );
};
