export const fetchWithToken = async (
  tokenRefresh: () => Promise<string | void>,
  url: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  const token = await tokenRefresh();
  if (!token) {
    return new Response(null, {
      status: 401,
      statusText: 'Unauthorized: Could not get the ID token.',
    });
  }

  const headersWithAuthorization = {
    ...init?.headers,
    Authorization: `Bearer ${token}`,
  };

  const baseUrl = import.meta.env.VITE_BACKEND_URL;

  return fetch(baseUrl + url, { ...init, headers: headersWithAuthorization });
};
