export const CheckIcon = ({
  color = '#007d00',
  className,
}: {
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='48'
      viewBox='0 -960 960 960'
      width='48'
      fill={color}
      className={className}
    >
      <path d='M480.134-120Q405-120 339.263-147.724q-65.738-27.724-114.75-76.691-49.012-48.966-76.763-114.642Q120-404.732 120-479.866q0-74.903 27.734-140.641 27.735-65.737 76.72-114.749 48.984-49.012 114.684-76.878Q404.838-840 480-840q57.692 0 111.231 17.5 53.538 17.5 100.461 50.731 6.385 3.923 7.731 10.538t-2.808 12q-4.153 5.385-10.769 5.231-6.615-.154-12.231-4.846-42.769-30.077-91.923-45.231T480-809.231q-138.077 0-233.654 95.577T150.769-480q0 138.077 95.577 233.654T480-150.769q138.077 0 233.654-95.577T809.231-480q0-20.839-1.885-39.843-1.884-19.003-5.884-37.542-2-6.862.923-12.854 2.923-5.992 9.678-6.453 6.706-.693 11.937 4.115 5.231 4.808 7.231 11.654 4 19.231 6.385 39.39Q840-501.374 840-480q0 75.162-27.839 140.862-27.84 65.7-76.806 114.684-48.967 48.985-114.642 76.72Q555.037-120 480.134-120ZM421-371.308l384.308-384.308q4.384-4.384 11.231-4.769 6.846-.384 12.398 4.937 5.217 5.217 5.217 11.679 0 6.461-5.385 11.846L440.462-344.384q-8.231 9-19.462 9t-19.462-9L292.615-453.538q-4.384-4.385-4.654-11.347-.269-6.961 4.885-12.115t11.731-5.154q6.577 0 11.731 5.154L421-371.308Z' />
    </svg>
  );
};
