export const AddIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
    >
      <path d='M304.615-620h270.77q8.5 0 14.25-5.758 5.75-5.757 5.75-14.269t-5.75-14.242q-5.75-5.731-14.25-5.731h-270.77q-8.5 0-14.25 5.758-5.75 5.757-5.75 14.269t5.75 14.242q5.75 5.731 14.25 5.731Zm0 160h150.77q8.5 0 14.25-5.758 5.75-5.757 5.75-14.269t-5.75-14.242q-5.75-5.731-14.25-5.731h-150.77q-8.5 0-14.25 5.758-5.75 5.757-5.75 14.269t5.75 14.242q5.75 5.731 14.25 5.731ZM700-300H600q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q591.5-340 600-340h100v-100q0-8.5 5.758-14.25 5.757-5.75 14.269-5.75t14.242 5.75Q740-448.5 740-440v100h100q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q848.5-300 840-300H740v100q0 8.5-5.758 14.25-5.757 5.75-14.269 5.75t-14.242-5.75Q700-191.5 700-200v-100Zm-468.461 0-63.949 63.949q-7.436 7.436-17.513 3.558Q140-236.372 140-247.231v-508.154q0-26.654 18.981-45.634Q177.961-820 204.615-820h470.77q26.654 0 45.634 18.981Q740-782.039 740-755.385v173.231q0 9.949-6.577 16.167-6.577 6.218-16.269 5.987-49.462 1.077-90.962 15.115-41.5 14.039-79.269 51.808-37.538 37.769-51.692 79.269-14.154 41.5-15.231 90.962.231 9.692-6.402 16.269Q466.966-300 457.846-300H231.539Z' />
    </svg>
  );
};
