import { useEffect, useState } from 'react';
import { useAppContext } from '../../state/useAppProvider';
import './ErrorPopupHandler.scss';
import { InfoIcon } from './InfoIcon';
import { CloseIcon } from './CloseIcon';

export const ErrorPopupHandler = () => {
  const { errorMessage, dispatch } = useAppContext();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (errorMessage.length === 0) return;

    setShowMessage(true);
    const removeMessage = () => {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: '' });
      setShowMessage(false);
    };

    const timedClose = setTimeout(removeMessage, 5000);

    return () => {
      clearTimeout(timedClose);
    };
  }, [dispatch, errorMessage]);

  return (
    <div className='error-message-container' data-visible={showMessage}>
      <InfoIcon />
      {errorMessage}
      <CloseIcon />
    </div>
  );
};
