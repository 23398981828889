import React, { useState, useEffect, useCallback } from 'react';
import { DocumentIcon } from './DocumentIcon';
import { UnfoldIcon } from './UnfoldIcon';
import './ProductDocumentPanel.scss';
import { useApiContext } from '../../../api/useApiContext';
import { ProductManual } from '../../../types';
import { ChatLoader } from '../chat-loader';
import { ExpandIcon } from './ExpandIcon';
import { CollapseIcon } from './CollapseIcon';
import { useTranslation } from 'react-i18next';
import { useProductHandler } from '../../../state/useProductHandler';
import { useAppContext } from '../../../state/useAppProvider';
import { useAuthContext } from '../../../api/useAuthContext';
export const ProductDocumentPanel: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { getProductDocuments } = useApiContext();
  const { getProductSubheader, selectedProducts } = useProductHandler();
  const { hideDocumentPanel, currentChat } = useAppContext();
  const { userSettings } = useAuthContext();
  const [view, setView] = useState<'open' | 'expanded' | 'collapsed'>(
    'collapsed',
  );
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<ProductManual[] | undefined>();
  const [includeEnglish, setIncludeEnglish] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [productIndex, setProductIndex] = useState(0);
  const currentProduct = selectedProducts && selectedProducts[productIndex];

  const loadDocuments = useCallback(
    async (index?: number) => {
      const product =
        index !== undefined ? selectedProducts?.[index] : currentProduct;
      if (!product) return;
      setLoading(true);
      setError(null);
      try {
        const regionToUse = currentChat?.region || userSettings?.region;
        
        const userLangDocs = await getProductDocuments(
          product.product_name,
          i18n.language,
          regionToUse
        );
        let allDocs = userLangDocs || [];
        if (includeEnglish && i18n.language !== 'en-US') {
          try {
            const englishDocs = await getProductDocuments(
              product.product_name,
              'en-US',
              regionToUse
            );
            if (englishDocs) {
              allDocs = [
                ...allDocs,
                ...englishDocs.filter(
                  (engDoc) =>
                    !allDocs.some((doc) => doc.file_name === engDoc.file_name),
                ),
              ];
            }
          } catch (englishError) {
            console.error('Error fetching English documents:', englishError);
          }
        }
        setDocuments(allDocs);
        if (allDocs.length === 0) {
          setError(`No documents available for ${product.product_name}.`);
        }
      } catch (error) {
        console.error('Failed to load documents:', error);
        setError('Failed to load documents. Please try again later.');
        setDocuments([]);
      } finally {
        setLoading(false);
      }
    },
    [
      currentProduct,
      getProductDocuments,
      i18n.language,
      includeEnglish,
      selectedProducts,
      currentChat?.region,
      userSettings?.region
    ],
  );

  useEffect(() => {
    const handleLanguageChanged = () => {
      loadDocuments();
    };
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n, loadDocuments]);

  const groupDocumentsByType = useCallback(
    (docs: ProductManual[]) => {
      const groupedDocs: Record<string, ProductManual[]> = {
        manuals: [],
        drawings: [],
        sales_and_technical_info: [],
        other: [],
      };
      docs.forEach((doc: ProductManual) => {
        const docType = doc.document_types[0];
        switch (docType) {
          case 'sem':
          case 'ins':
          case 'inm':
          case 'usm':
            groupedDocs.manuals.push(doc);
            break;
          case 'dra':
          case 'wid':
            groupedDocs.drawings.push(doc);
            break;
          case 'sti':
            groupedDocs.sales_and_technical_info.push(doc);
            break;
          case 'err':
          default:
            groupedDocs.other.push(doc);
            break;
        }
      });
      return {
        [t('document_panel.manuals', 'Manuals') as string]: groupedDocs.manuals,
        [t('document_panel.wiring_diagram', 'Wiring Diagram') as string]:
          groupedDocs.drawings,
        [t(
          'document_panel.sales_and_technical_info',
          'Sales and Technical Information',
        ) as string]: groupedDocs.sales_and_technical_info,
        [t('document_panel.other', 'Other') as string]: groupedDocs.other,
      };
    },
    [t],
  );

  const filteredDocuments = useCallback(() => {
    if (!documents) return groupDocumentsByType([]);
    const docsToFilter = includeEnglish
      ? documents
      : documents.filter((doc: ProductManual) => {
          const currentLang = i18n.language.toLowerCase().split('-')[0];
          return doc.languages.some((lang: string) =>
            lang.toLowerCase().startsWith(currentLang),
          );
        });
    return groupDocumentsByType(docsToFilter);
  }, [includeEnglish, documents, groupDocumentsByType, i18n.language]);

  const toggleExpand = (index: number) => {
    if ((view === 'collapsed' && !documents) || index !== productIndex) {
      loadDocuments(index);
      setView('open');
      setProductIndex(index);
    } else {
      setView((prev) => (prev === 'collapsed' ? 'open' : 'collapsed'));
    }
  };

  const collapse = () => setView('collapsed');

  const toggleMaximize = () => {
    setView((prev) => (prev === 'open' ? 'expanded' : 'open'));
  };

  const handleToggleLanguages = () => {
    setIncludeEnglish((prev) => !prev);
    loadDocuments();
  };

  if (!currentProduct) return null;

  const displayedDocuments = filteredDocuments();

  return (
    !hideDocumentPanel && (
      <>
        <div
          data-view={view}
          onClick={collapse}
          className='document-panel-backdrop'
        ></div>
        <div data-view={view} className='product-document-panel'>
          <div className='document-panel-container'>
            <div className='product-document-tablist'>
              <button
                data-metrics-id='RelatedDocumentsButton'
                onClick={() => toggleExpand(0)}
                className={`product-document-button ${view !== 'collapsed' ? 'active' : ''}`}
              >
                <UnfoldIcon /> {t('document_panel.related_documents', 'Related Documents')}
              </button>
            </div>
            <div data-view={view} className='product-document-content'>
              <div className='product-document-header'>
                <div className='product-tabs'>
                  {selectedProducts.map((product, index) => (
                    <button
                      key={index}
                      onClick={() => toggleExpand(index)}
                      className={`product-tab ${index === productIndex ? 'active' : ''}`}
                      disabled={index === productIndex}
                    >
                      <div className='product-tab-subheader'>{getProductSubheader(product)}</div>
                      <div className='product-tab-name'>{product.display_name}</div>
                    </button>
                  ))}
                </div>
                <div className='product-document-info'>
                  <div className='product-document-title-container'>
                    <h3 className='product-document-title'>{currentProduct.display_name}</h3>
                  </div>
                  <div className='toggle-container'>
                    <label htmlFor='toggleLanguages' className='toggle-label'>
                      {t('document_panel.toggle_language.include_english', 'Include English language')}
                    </label>
                    <input
                      type='checkbox'
                      id='toggleLanguages'
                      className='toggle-switch'
                      checked={includeEnglish}
                      onChange={handleToggleLanguages}
                    />
                  </div>
                  <div className='header-buttons-container'>
                    <button
                      data-metrics-id='ProductDocumentMaximizeButton'
                      onClick={toggleMaximize}
                      className='product-document-expand'
                    >
                      {view === 'expanded' ? <CollapseIcon /> : <ExpandIcon />}
                    </button>
                  </div>
                </div>
              </div>
              {loading ? (
                <ChatLoader
                  message={t('document_panel.loading', 'Loading...')}
                />
              ) : (
                <div className='product-document-list'>
                  {error && <div className='error-message'>{error}</div>}
                  {Object.entries(displayedDocuments).map(
                    ([type, docs], idx) =>
                      docs.length > 0 && (
                        <div key={idx}>
                          <div className='product-document-item-row-group'>
                            {/* Accordion clickable header */}
                            <div className='product-document-group-header-section'>
                              <h3 className='product-document-item-header'>
                                {type}
                              </h3>
                              <div className='document-language-header'>
                                {t('settings.language', {
                                  defaultValue: 'Language',
                                })}
                              </div>
                            </div>
                            {/* Accordion container */}
                            <div className='product-document-item-container'>
                              {docs.length === 0 ? (
                                <div className='no-documents-message'>
                                  {t(
                                    'document_panel.no_documents',
                                    'No documents available',
                                  )}
                                </div>
                              ) : (
                                docs.map((doc, index) => (
                                  <div
                                    key={`${doc.file_name}-${doc.languages.join(
                                      '-',
                                    )}-${index}`}
                                    className='product-document-item-row'
                                  >
                                    <a
                                      data-metrics-id={`ProductDocument-${doc.file_name}`}
                                      href={doc.url}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='product-document-item'
                                    >
                                      <div className='product-document-item-name'>
                                        <DocumentIcon />
                                        {doc.file_name}
                                      </div>
                                      <div className='documentLanguage'>
                                        {doc.languages
                                          .map((lang) => lang.toUpperCase())
                                          .join(', ')}
                                      </div>
                                    </a>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      ),
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};
