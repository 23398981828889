import { useTranslation } from 'react-i18next';
import '../user-setup-wizard/UserSetupWizard.scss';

export const Disclaimer = () => {
  const { t } = useTranslation();
  const bullets = t('disclaimer.points', { returnObjects: true });

  return (
    <div className="wizard-step disclaimer-step">
      <h3>{t('disclaimer.heading')}</h3>
      <p className="account-wizard-disclaimer-text">
        {t('disclaimer.ingress')}
      </p>
      <ol className="disclaimer-list">
        {bullets.map((bullet: any, index: number) => (
          <li key={index}>
            <h4>{bullet.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: bullet.text }}></p>
          </li>
        ))}
      </ol>
      <p className="account-wizard-disclaimer-text">
        {t('disclaimer.conclusion')}
      </p>
      <a
        className="disclaimer-link"
        href="https://avenue.assaabloy.net/group-library/health-and-safety"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('disclaimer.conclusion_link')}
      </a>
    </div>
  );
};