export const CloseIcon = ({ fill }: { fill?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      fill={fill || '#000000'}
    >
      <path d='M480-451.692 270.154-241.846q-5.615 5.615-13.769 6-8.154.385-14.539-6T235.461-256q0-7.769 6.385-14.154L451.692-480 241.846-689.846q-5.615-5.615-6-13.769-.385-8.154 6-14.539T256-724.539q7.769 0 14.154 6.385L480-508.308l209.846-209.846q5.615-5.615 13.769-6 8.154-.385 14.539 6T724.539-704q0 7.769-6.385 14.154L508.308-480l209.846 209.846q5.615 5.615 6 13.769.385 8.154-6 14.539T704-235.461q-7.769 0-14.154-6.385L480-451.692Z' />
    </svg>
  );
};
