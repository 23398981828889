import { useEffect, useState } from 'react';
import { ChatMessage, Language, SpeechToken } from '../../types';
import {
  AudioConfig,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
} from 'microsoft-cognitiveservices-speech-sdk';
import { SpeechButton } from '../buttons/Buttons';
import { useAppContext } from '../../state/useAppProvider';
import { useApiContext } from '../../api/useApiContext';
import { nanoid } from 'nanoid';
import { useMessageHandlerContext } from '../../state/useMessageHandlerContext';
import { useTranslation } from 'react-i18next';

type SpeechToTextButtonProps = {
  selectedLanguage?: Language;
  disabled?: boolean;
};

export const SpeechToTextButton = ({
  selectedLanguage = 'en-US',
  disabled = false,
}: SpeechToTextButtonProps) => {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();
  const { getSpeechToken } = useApiContext();
  const { addNewMessage } = useMessageHandlerContext();
  const [isMicOn, setIsMicOn] = useState<boolean>(false);
  const [authenticationData, setAuthenticationData] = useState<
    SpeechToken | undefined
  >();

  // TODO: This is taken from the old source code and needs to be fixed before it can be used.
  /* const handleTextToSpeech = async () => {
    if (
      !authenticationData ||
      authenticationData.authToken === null ||
      authenticationData.region === undefined
    )
      throw new Error("Fetching auth token for Speech API failed");
    const speechConfig = SpeechConfig.fromAuthorizationToken(
      authenticationData.authToken,
      authenticationData.region
    );

    if (selectedLanguage !== "auto") {
      speechConfig.speechSynthesisLanguage = selectedLanguage;
      speechConfig.speechSynthesisVoiceName =
        voiceToLanguageMap[selectedLanguage];
    }

    const speaker = new SpeakerAudioDestination();
    speaker.onAudioEnd = () => {
      handleStopSpeakerOutput();
    };

    updateVoiceOutput({ speaker, muted: false });

    const audioConfig = AudioConfig.fromSpeakerOutput(speaker);

    const speechSynthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

    const lastMessage = messages[messages.length - 1];

    speechSynthesizer.speakTextAsync(lastMessage.content, () => {
      speechSynthesizer.close();
    });
    setIsQuestionFromMic(false);
  }; */

  const cancelSpeechToText = (speechRecognizer: SpeechRecognizer) => {
    speechRecognizer.close();
    setIsMicOn(false);
    dispatch({
      type: 'SET_PENDING_ANSWER',
      payload: undefined,
    });
  };

  const addVoiceResultToMessage = (
    initialMessage: ChatMessage,
    content: string,
  ) => {
    dispatch({
      type: 'UPDATE_PENDING_ANSWER',
      payload: {
        messageContent: { ...initialMessage, content },
      },
    });
  };

  const handleSpeechToText = () => {
    if (
      !authenticationData ||
      authenticationData.authToken === null ||
      authenticationData.region === undefined
    ) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: t('errors.speech_token_failed'),
      });
      throw new Error('Fetching auth token for Speech API failed');
    }
    const speechConfig = SpeechConfig.fromAuthorizationToken(
      authenticationData.authToken,
      authenticationData.region,
    );

    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();

    speechConfig.speechRecognitionLanguage = selectedLanguage;

    const speechRecognizer = new SpeechRecognizer(speechConfig, audioConfig);

    const initialMessage: ChatMessage = {
      id: nanoid(),
      date: new Date().toISOString(),
      role: 'user',
      content: '',
      metadata: { speech: true, language: selectedLanguage },
    };

    //Set up initial pending voice message with loading state and cancel function.
    dispatch({
      type: 'SET_PENDING_ANSWER',
      payload: {
        type: 'speech',
        messageContent: initialMessage,
        loading: true,
        onCancel: () => cancelSpeechToText(speechRecognizer),
      },
    });

    speechRecognizer.recognizing = (_r, e) => {
      addVoiceResultToMessage(initialMessage, e.result.text);
    };

    speechRecognizer.sessionStarted = () => {
      setIsMicOn(true);
      dispatch({ type: 'UPDATE_PENDING_ANSWER', payload: { loading: false } });
    };

    speechRecognizer?.recognizeOnceAsync((result) => {
      if (result.reason == ResultReason.RecognizedSpeech) {
        addVoiceResultToMessage(initialMessage, result.text);
        setTimeout(() => {
          addNewMessage({ ...initialMessage, content: result.text });
          dispatch({
            type: 'SET_PENDING_ANSWER',
            payload: undefined,
          });
        }, 2500);
      } else {
        cancelSpeechToText(speechRecognizer);
      }
      setIsMicOn(false);
    });
  };

  useEffect(() => {
    if (!authenticationData) {
      getSpeechToken()
        .then((res) => setAuthenticationData(res))
        .catch((err) => console.error(err));
    }

    const setAuthInterval = setInterval(
      () => {
        getSpeechToken()
          .then((res) => setAuthenticationData(res))
          .catch((err) => console.error(err));
      },
      8 * 60 * 1000,
    );

    return () => clearInterval(setAuthInterval);
  }, [authenticationData, getSpeechToken]);

  return (
    <SpeechButton
      onClick={handleSpeechToText}
      micActive={isMicOn}
      disabled={isMicOn || disabled}
    />
  );
};
