export const endpoints = {
  conversation: '/api/conversation',
  historyList: '/history/list',
  historyRead: '/history/read',
  historyUpdate: '/history/update',
  historyDelete: '/history/delete',
  historyDeleteAll: '/history/delete-all',
  historyClear: '/history/clear',
  historyRename: '/history/rename',
  getStorageToken: '/api/get-storage-token',
  getSpeechToken: '/api/get-speech-token',
  analyzeImage: '/api/analyze-image',
  feedback: '/api/feedback',
  info: '/api/info',
  productFilters: '/api/metadata/products',
  user: '/api/user',
  productDocuments: '/api/files',
};

export type Endpoint = keyof typeof endpoints;
