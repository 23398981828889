import { Action, AppState, ConversationState } from '../types';

// Define the reducer function
export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'SET_CHAT_MENU_OPEN':
      return { ...state, isChatMenuOpen: action.payload };
    case 'UPDATE_CURRENT_CHAT': {
      return {
        ...state,
        currentChat: { ...state.currentChat, ...action.payload },
      };
    }
    case 'CLEAR_CURRENT_CHAT':
      return {
        ...state,
        currentChat: {
          messages: [],
          region: state.currentChat.region,
        },
      };
    case 'SET_PENDING_ANSWER':
      return {
        ...state,
        pendingMessage: action.payload,
      };
    case 'UPDATE_PENDING_ANSWER':
      if (!state.pendingMessage) return state;
      return {
        ...state,
        pendingMessage: { ...state.pendingMessage, ...action.payload },
      };
    case 'ADD_PENDING_ANSWER_TO_CHAT':
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          messages: [...state.currentChat.messages, action.payload],
        },
        pendingMessage: undefined,
      };
    case 'ADD_MESSAGE_TO_CHAT': {
      const newMessages = [...state.currentChat.messages, action.payload];
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          messages: newMessages,
        },
      };
    }
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_IMAGE_TO_ANALYZE':
      return { ...state, imageToAnalyze: action.payload };
    case 'CREATE_NEW_CHAT': {
      const { region, language, data_filter } = action.payload;
      return {
        ...state,
        currentChat: {
          messages: [],
          region,
          language,
          data_filter,
        },
        conversationState: ConversationState.UNKNOWN,
        productFilter: undefined,
      };
    }
    case 'UPDATE_CHAT_HISTORY_LIST':
      return { ...state, chatHistory: action.payload };
    case 'SET_ACTIVE_TOGGLES':
      return { ...state, activeToggles: action.payload };
    case 'SET_CONVERSATION_STATE':
      return {
        ...state,
        conversationState: action.payload,
      };
    case 'SET_PRODUCT_FILTER':
      return {
        ...state,
        productFilter:
          state.productFilter && action.payload
            ? { ...state.productFilter, ...action.payload }
            : action.payload,
      };
    case 'SET_USER_SPECIFIED':
      if (!state.currentChat) return state;
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          user_specified: action.payload,
        },
      };
    case 'ADD_USER_SPECIFIED':
      if (!state.currentChat) return state;
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          user_specified: [
            ...(state.currentChat.user_specified ?? []),
            action.payload,
          ],
        },
      };
    case 'SET_SHOW_PRODUCT_TAGS':
      return { ...state, showProductTags: action.payload };
    case 'SET_HIDE_DOCUMENT_PANEL':
      return { ...state, hideDocumentPanel: action.payload };
    case 'SET_PRODUCT_CHOICES':
      return { ...state, productChoices: action.payload };
    default:
      return state;
  }
};
