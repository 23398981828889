import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguagueDetector from 'i18next-browser-languagedetector';
import cs from './locales/cs-CZ/translation.json';
import en from './locales/en-US/translation.json';
import da from './locales/da-DK/translation.json';
import de from './locales/de-DE/translation.json';
import es from './locales/es-ES/translation.json';
import fi from './locales/fi-FI/translation.json';
import fr from './locales/fr-FR/translation.json';
import it from './locales/it-IT/translation.json';
import nb from './locales/nb-NO/translation.json';
import nl from './locales/nl-NL/translation.json';
import pl from './locales/pl-PL/translation.json';
import pt from './locales/pt-PT/translation.json';
import sv from './locales/sv-SE/translation.json';
import ro from './locales/ro-RO/translation.json';
import ko from './locales/ko-KR/translation.json';
import enGB from './locales/en-GB/translation.json';
import huHU from './locales/hu-HU/translation.json';
import msMS from './locales/ms-MY/translation.json';
import ptBR from './locales/pt-BR/translation.json';
import sk from './locales/sk-SK/translation.json';
import sl from './locales/sl-SI/translation.json';
import tr from './locales/tr-TR/translation.json';
import yueHans from './locales/yue-Hans/translation.json';
import yueHant from './locales/yue-Hant/translation.json';
import zhHans from './locales/zh-Hans/translation.json';
import zhHant from './locales/zh-Hant/translation.json';

i18next
  .use(initReactI18next)
  .use(LanguagueDetector)
  .init({
    fallbackLng: 'en-US',
    debug: import.meta.env.DEV,
    //debug: false,
    resources: {
      'cs-CZ': {
        translation: cs,
      },
      'da-DK': {
        translation: da,
      },
      'es-ES': {
        translation: es,
      },
      'fi-FI': {
        translation: fi,
      },
      'it-IT': {
        translation: it,
      },
      'en-US': {
        translation: en,
      },
      'de-DE': {
        translation: de,
      },
      'fr-FR': {
        translation: fr,
      },
      'nb-NO': {
        translation: nb,
      },
      'nl-NL': {
        translation: nl,
      },
      'pl-PL': {
        translation: pl,
      },
      'pt-PT': {
        translation: pt,
      },
      'sv-SE': {
        translation: sv,
      },
      'ro-RO': {
        translation: ro,
      },
      'ko-KR': {
        translation: ko,
      },
      'en-GB': {
        translation: enGB,
      },
      'hu-HU': {
        translation: huHU,
      },
      'ms-MY': {
        translation: msMS,
      },
      'pt-BR': {
        translation: ptBR,
      },
      'sk-SK': {
        translation: sk,
      },
      'sl-SI': {
        translation: sl,
      },
      'tr-TR': {
        translation: tr,
      },
      'yue-Hans': {
        translation: yueHans,
      },
      'yue-Hant': {
        translation: yueHant,
      },
      'zh-Hans': {
        translation: zhHans,
      },
      'zh-Hant': {
        translation: zhHant,
      },
    },
  });
