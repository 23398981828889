export const CollapseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='30px'
      viewBox='0 -960 960 960'
      width='30px'
      fill='#000000'
    >
      <path d='M400-400H260q-8.5 0-14.25-5.76T240-420.03q0-8.51 5.75-14.24T260-440h147.69q13.73 0 23.02 9.29t9.29 23.02V-260q0 8.5-5.76 14.25T419.97-240q-8.51 0-14.24-5.75T400-260v-140Zm160-160h140q8.5 0 14.25 5.76t5.75 14.27q0 8.51-5.75 14.24T700-520H552.31q-13.73 0-23.02-9.29T520-552.31V-700q0-8.5 5.76-14.25t14.27-5.75q8.51 0 14.24 5.75T560-700v140Z' />
    </svg>
  );
};
