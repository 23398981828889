import {
  AppState,
  ChatMessage,
  Conversation,
  ConversationState,
  Region,
} from '../types';

export const featureToggles = ['devmode'];

export const emptyChat: Conversation = {
  messages: [] as ChatMessage[],
  region: Region.EU,
};

export const initialState: AppState = {
  isChatMenuOpen: false,
  //chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
  errorMessage: '',
  currentChat: { ...emptyChat },
  imageToAnalyze: '',
  conversationState: ConversationState.UNKNOWN,
};
