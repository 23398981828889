export const ThumbsUpIcon = ({ selected }: { selected?: boolean }) => {
  return selected ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      fill='#0393be'
    >
      <path d='M698.462-160H306.154v-440l250.769-247.693L574.616-830q5.461 5.461 9.192 14 3.731 8.538 3.731 15.692V-794l-40.923 194h268.769q25.077 0 44.846 19.769Q880-560.462 880-535.385v49.231q0 5.462-1.115 11.923-1.116 6.462-3.346 11.923L766.308-203.384q-8.231 18.461-27.692 30.923Q719.154-160 698.462-160ZM266.154-600v440H120v-440h146.154Z' />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      fill='#1a1a1a'
    >
      <path d='M698.462-160H296.923v-440l250.769-247.693L565.385-830q5.461 5.461 9.192 14 3.731 8.538 3.731 15.692V-794l-40.923 194h278q25.077 0 44.846 19.769Q880-560.462 880-535.385v49.231q0 5.462-1.231 11.923-1.23 6.462-3.23 11.923L766.308-203.384q-8.231 18.461-27.692 30.923Q719.154-160 698.462-160Zm-361.539-40h361.539q8.461 0 17.307-4.615 8.846-4.616 13.462-15.385L840-480v-55.385q0-10.769-6.923-17.692T815.385-560H487.692L534-778.462 336.923-582.923V-200Zm0-382.923V-200v-382.923Zm-40-17.077v40H160v360h136.923v40H120v-440h176.923Z' />
    </svg>
  );
};
