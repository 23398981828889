import { ChatIcon } from './ChatIcon';
import './ChatMenu.scss';
import { useAppContext } from '../../state/useAppProvider';
import { useEffect } from 'react';
import { useApiContext } from '../../api/useApiContext';
import { AddIcon } from './AddIcon';
import { useWindowSize } from '../../hooks/use-window-width';
import { VersionInfoPanel } from '../version-info/VersionInfo';
import { useMessageHandlerContext } from '../../state/useMessageHandlerContext';
import { useAuthContext } from '../../api/useAuthContext';
import { useTranslation } from 'react-i18next';
import { ChatLoader } from '../chat/chat-loader';
import { ConversationSummary } from '../../types';
import { useNavigateWithTracker } from '../../hooks/use-navigate-with-tracker';
import { Pages } from '../../pages/constants/pages';

const ChatHistory = ({
  history,
  isMobile,
  closeMenu,
}: {
  history?: ConversationSummary[];
  isMobile: boolean;
  closeMenu: () => void;
}) => {
  const { currentChat, dispatch } = useAppContext();
  const { abortConversationRequest } = useApiContext();
  const { t } = useTranslation();
  const navigate = useNavigateWithTracker();
  const showFailMessage = !history || history.length === 0;

  const gotoChat = (chatId: string) => {
    if (chatId === currentChat.id) return;
    if (isMobile) {
      closeMenu();
    }
    abortConversationRequest();
    dispatch({ type: 'CLEAR_CURRENT_CHAT' });
    navigate(Pages.CHAT, `/chat/${chatId}`);
  };

  return (
    <>
      {showFailMessage ? (
        <div className='chat-history-failed-container'>
          <h4>{t('errors.chat_history_fetch_failed')}</h4>
        </div>
      ) : (
        history.slice(0, 20).map((chat) => {
          const isActive = chat.id === currentChat.id;
          const formattedTitle =
            chat.title.length > 26
              ? `${chat.title.substring(0, 25)}...`
              : chat.title;
          return (
            <button
              data-metrics-id='ChatHistoryItem'
              data-chatid={chat.id}
              title={chat.title}
              onClick={() => gotoChat(chat.id)}
              key={chat.id}
              className='chat-menu-item'
              data-active={isActive}
            >
              <ChatIcon active={isActive} />
              {formattedTitle}
            </button>
          );
        })
      )}
    </>
  );
};

export const ChatMenu = () => {
  const { t } = useTranslation();
  const { isChatMenuOpen, currentChat, chatHistory, dispatch } =
    useAppContext();
  const { userSettings } = useAuthContext();
  const { conversationHistoryList, loadingHistory, abortConversationRequest } =
    useApiContext();
  const { createNewChat } = useMessageHandlerContext();
  const navigate = useNavigateWithTracker();
  const { isMobile } = useWindowSize();

  // Fetch the chat history on component mount.
  useEffect(() => {
    if (chatHistory || loadingHistory) return;
    conversationHistoryList();
  }, [conversationHistoryList, chatHistory, loadingHistory]);

  const startNewChat = () => {
    if (isMobile) {
      closeMenu();
    }
    abortConversationRequest();
    createNewChat();
    navigate(Pages.HOME, '/');
  };

  const closeMenu = () => {
    dispatch({ type: 'SET_CHAT_MENU_OPEN', payload: false });
  };

  return (
    <>
      <div className='mini-sidebar' data-menu-open={isChatMenuOpen}>
        <button
          data-metrics-id='SmallNewChatButton'
          onClick={startNewChat}
          disabled={currentChat.messages.length < 1}
          className='new-chat-icon-button'
        >
          <AddIcon />
          <span className='new-chat-tooltip'>{t('chat_menu.new_chat')}</span>
        </button>
      </div>
      <div
        className='mobile-menu-backdrop'
        data-open={isChatMenuOpen}
        onClick={closeMenu}
      ></div>
      <aside className='sidebar-wrapper' data-open={isChatMenuOpen}>
        <div className='sidebar' data-menu-open={isChatMenuOpen}>
          <div className='chat-menu'>
            <button
              data-metrics-id='NewChatButton'
              onClick={startNewChat}
              disabled={currentChat.messages.length < 1}
              className='new-chat-button'
            >
              <AddIcon /> {t('chat_menu.new_chat')}
            </button>
            <h2 className='chat-menu-title'>
              {t('chat_menu.chat_history_title')}
            </h2>
            <div className='chat-history'>
              {loadingHistory ? (
                <div className='chat-history-loader-container'>
                  <ChatLoader message={t('chat_menu.loading')} size='small' />
                </div>
              ) : (
                <ChatHistory
                  history={chatHistory}
                  isMobile={isMobile}
                  closeMenu={closeMenu}
                />
              )}
            </div>
          </div>
          <div className='sidebar-footer'>
            <VersionInfoPanel />
            {userSettings?.data_filter && (
              <p className='user-info-text'>
                {t('settings.business_segment')}
                {': '}
                <strong>{userSettings.data_filter}</strong>
              </p>
            )}
            {userSettings?.region && (
              <p className='user-info-text'>
                {t('chat_menu.region_label')}{' '}
                <strong>{userSettings.region.toUpperCase()}</strong>
              </p>
            )}
          </div>
        </div>
      </aside>
    </>
  );
};
