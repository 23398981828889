import './Buttons.scss';
import { useTranslation } from 'react-i18next';

type DefaultButtonProps = {
  text?: string;
  icon?: string;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const DefaultButton = (props: DefaultButtonProps) => {
  const { text, onClick, icon, className, disabled, onBlur } = props;
  return (
    <button
      onBlur={onBlur}
      disabled={disabled}
      className={className}
      onClick={onClick}
      {...props}
    >
      {icon && (
        <img
          className='button-icon'
          src={`/icons/${icon}.svg`}
          aria-hidden='true'
        />
      )}
      <span>{text}</span>
    </button>
  );
};

// export const HistoryButton = ({ onClick, text }: DefaultButtonProps) => {
//   return (
//     <DefaultButton
//       text={text}
//       icon='history'
//       onClick={onClick}
//       className='history-button'
//     />
//   );
// };

export const SpeechButton = ({
  onClick,
  disabled,
  micActive,
}: DefaultButtonProps & { micActive?: boolean }) => {
  const { t } = useTranslation();
  return (
    <DefaultButton
      disabled={disabled}
      data-metrics-id='SpeechButton'
      aria-label={t('chat_window.input.voice_button')}
      icon={micActive ? 'mic-active' : 'mic-inactive'}
      onClick={onClick}
      className='speech-button'
    />
  );
};

export const ImageButton = ({ onClick, onBlur }: DefaultButtonProps) => {
  const { t } = useTranslation();
  return (
    <DefaultButton
      data-metrics-id='ImageButton'
      aria-label={t('chat_window.input.image_button')}
      onBlur={onBlur}
      icon='image'
      onClick={onClick}
      className='image-button'
    />
  );
};

export const SendButton = ({ onClick, disabled }: DefaultButtonProps) => {
  const { t } = useTranslation();
  return (
    <DefaultButton
      data-metrics-id='SendButton'
      disabled={disabled}
      icon='send'
      onClick={onClick}
      className='send-button'
      aria-label={t('chat_window.input.submit')}
    />
  );
};
