import './SettingsBadge.scss';
import { SettingsIcon } from './SettingsIcon';
import { useTranslation } from 'react-i18next';

export const SettingsBadge = ({
  onGotoUserSettings,
}: {
  onGotoUserSettings: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <button
      data-metrics-id='UserSettingsButton'
      aria-label='Open settings'
      onClick={onGotoUserSettings}
      className='settings-badge-button'
    >
      <SettingsIcon />
      {t('user_menu.settings')}
    </button>
  );
};
