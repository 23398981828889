import './PromptInput.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SendButton } from '../../buttons/Buttons';
import { useDynamicInputHeight } from '../../../hooks/use-dynamic-input-height';
import { SpeechToTextButton } from '../SpeechToTextButton';
import {
  ChatMessage,
  ConversationState,
  MessageMetadata,
} from '../../../types';
import { nanoid } from 'nanoid';
import { useAppContext } from '../../../state/useAppProvider';
import { debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../api/useAuthContext';
// import { ImageUploadMenu } from '../image-upload-panel/ImageUploadMenu';

export type SubmitProps = {
  question: string;
  metadata?: MessageMetadata;
};

type PromptInputProps = {
  disabled?: boolean;
  onSubmit: (newUserMessage: ChatMessage) => void;
};

export const PromptInput = ({ onSubmit, disabled }: PromptInputProps) => {
  const { t } = useTranslation();
  const { userSettings } = useAuthContext();
  const { conversationState } = useAppContext();
  const productSelectionMode =
    conversationState === ConversationState.PRODUCT_SELECTION;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [question, setQuestion] = useState<string>('');
  const textMode = question.length > 0;
  const [showProductMessage, setShowProductMessage] = useState<boolean>(false);
  useDynamicInputHeight(textareaRef);

  const hideInfoMessage = useMemo(
    () =>
      debounce(() => {
        setShowProductMessage(false);
      }, 3500),
    [],
  );

  //Hide message after delay when it has been shown.
  const showTempMessage = useCallback(() => {
    setShowProductMessage(true);
    hideInfoMessage();
  }, [hideInfoMessage]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value);
    if (productSelectionMode) {
      showTempMessage();
    }
  };

  const handleSubmit = useCallback(() => {
    if (!productSelectionMode && question.length > 0) {
      const newUserMessage: ChatMessage = {
        id: nanoid(),
        date: new Date().toISOString(),
        content: question,
        role: 'user',
      };
      onSubmit(newUserMessage);
      setQuestion('');
    } else if (productSelectionMode) {
      showTempMessage();
    }
  }, [productSelectionMode, question, onSubmit, showTempMessage]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  //Prevents new line from being added when submitting using Enter.
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
    }
  };

  // const imageToTextCallback = (text: string) => {
  //   setQuestion(text);
  //   handleSubmit();
  // };

  return (
    <div className='chat-input-container'>
      {!textMode && (
        <div className='media-action-panel'>
          <SpeechToTextButton
            disabled={disabled}
            selectedLanguage={userSettings?.language}
          />
          {/* <ImageUploadMenu resultCallback={imageToTextCallback} /> */}
        </div>
      )}

      <div className='prompt-info-message' data-visible={showProductMessage}>
        {t('chat_window.input.warning_message')}
      </div>
      <textarea
        data-metrics-id='ChatInput'
        aria-label='Question input'
        data-info={showProductMessage}
        data-text-mode={textMode}
        rows={1}
        value={question}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        ref={textareaRef}
        placeholder={t('chat_window.input.placeholder')}
        className='chat-textarea'
      />
      {textMode && (
        <div className='submit-button-container'>
          <SendButton disabled={disabled} onClick={() => handleSubmit()} />
        </div>
      )}
    </div>
  );
};
