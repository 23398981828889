import { useAppContext } from '../../../state/useAppProvider';
import { ConversationState } from '../../../types';
import { AddProductPrompt } from './add-product-prompt';
import { ProductSelectionMenu } from './product-selection-menu';

export const ProductSelector = () => {
  const { conversationState } = useAppContext();
  return conversationState === ConversationState.PRODUCT_SELECTION ? (
    <ProductSelectionMenu />
  ) : (
    <AddProductPrompt />
  );
};
