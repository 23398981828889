export const UserCompleteIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='160px'
      viewBox='0 -960 960 960'
      width='160px'
      fill='#00a0d0'
    >
      <path d='M150-215.38q-13.21 0-22.76-9.55t-9.55-22.76v-25.55q0-30.91 15.08-49.41 15.08-18.5 39.85-30.43 61.23-28.15 108.53-40.61 47.31-12.46 116.54-12.46 12.46 0 26.31.75 13.85.75 25.85 1.48 5.96.78 10.17 6.25t3.75 11.99q-1.23 5.99-6.5 10.03-5.27 4.03-12.65 3.03-10.93-1-22-1.88-11.08-.88-24.93-.88-64.92 0-111.27 11.65-46.34 11.65-99.8 37.73-22.39 10.31-30.27 23.77-7.89 13.46-7.89 29v27.08h303.62q6.54 0 10.96 4.48 4.42 4.49 4.42 11.12 0 6.63-4.42 10.9-4.42 4.27-10.96 4.27H150Zm463.46-.31 195.85-195.85q4.38-4.38 10.61-4.77 6.23-.38 11.39 4.77 5.15 5.16 5.15 11 0 5.85-5.15 11L632.92-191.15q-8.37 8.23-19.53 8.23-11.16 0-19.39-8.23l-81.31-81.31q-4.38-4.39-4.77-10.62-.38-6.23 4.77-11.38 5.16-5.16 11-5.16 5.85 0 11 5.16l78.77 78.77ZM397.69-505.85q-49.5 0-82.44-32.55-32.94-32.56-32.94-82.45 0-49.88 32.94-82.82 32.94-32.95 82.44-32.95t82.44 32.95q32.95 32.94 32.95 82.82 0 49.89-32.95 82.45-32.94 32.55-82.44 32.55Zm11.16 252.62Zm-11.16-283.39q35.93 0 60.27-24.34 24.35-24.35 24.35-60.27t-24.35-60.27q-24.34-24.35-60.27-24.35-35.92 0-60.27 24.35-24.34 24.35-24.34 60.27t24.34 60.27q24.35 24.34 60.27 24.34Zm0-84.61Z' />
    </svg>
  );
};
