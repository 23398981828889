import { CSSProperties } from 'react';
import './ChatLoader.scss';
import Lottie from 'react-lottie-player';
import * as loaderAnimation from '../../../animations/loader.json';

type ChatLoaderStyles = {
  large: CSSProperties;
  small: CSSProperties;
};

const chatLoaderStyles: ChatLoaderStyles = {
  large: {
    height: '120px',
    width: '120px',
  },
  small: {
    height: '90px',
    width: '90px',
  },
};

export const ChatLoader = ({
  message,
  size = 'large',
}: {
  message: string;
  size?: 'small' | 'large';
}) => {
  return (
    <div className='chat-loader'>
      <Lottie
        animationData={loaderAnimation}
        play
        loop
        style={chatLoaderStyles[size]}
      />
      <h2 className={`chat-loader-text-${size}`}>{message}</h2>
    </div>
  );
};
