export const UnfoldIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='25px'
      viewBox='0 -960 960 960'
      width='25px'
    >
      <path d='m480-206.77 110.69-110.69q5.85-5.85 14-6.23 8.16-.39 14.77 6.23 6.62 6.61 6.62 14.38 0 7.77-6.62 14.39L502.62-171.85q-5.24 5.23-10.7 7.35-5.46 2.12-11.92 2.12t-11.92-2.12q-5.46-2.12-10.7-7.35L340.54-288.69q-5.85-5.85-6.23-14-.39-8.16 6.23-14.77 6.61-6.62 14.38-6.62 7.77 0 14.39 6.62L480-206.77Zm0-545.54L369.31-641.62q-5.85 5.85-14 6.24-8.16.38-14.77-6.24-6.62-6.61-6.62-14.38 0-7.77 6.62-14.38l116.84-116.85q5.24-5.23 10.7-7.35 5.46-2.11 11.92-2.11t11.92 2.11q5.46 2.12 10.7 7.35l116.84 116.85q5.85 5.84 6.23 14 .39 8.15-6.23 14.76-6.61 6.62-14.38 6.62-7.77 0-14.39-6.62L480-752.31Z' />
    </svg>
  );
};
