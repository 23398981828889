import { useState } from 'react';
import './UserMenu.scss';
import { useAuthContext } from '../../api/useAuthContext';
import { SettingsBadge } from '../settings-badge/SettingsBadge';
import { useTranslation } from 'react-i18next';
import { useNavigateWithTracker } from '../../hooks/use-navigate-with-tracker';
import { Pages } from '../../pages/constants/pages';

export const UserMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigateWithTracker();
  const { logout, userData, getUserSettings } = useAuthContext();
  const [showUserInfo, setShowUserInfo] = useState(false);

  const onUserHover = () => {
    setShowUserInfo(true);
  };

  const onUserLeave = () => {
    setShowUserInfo(false);
  };

  const gotoUserSettings = () => {
    setShowUserInfo(false);
    getUserSettings();
    navigate(Pages.SETTINGS, '/settings');
  };

  if (!userData) return null;

  return (
    <div className='user-badge-wrapper'>
      <button
        data-metrics-id='UserMenuButton'
        aria-label={t('user_menu.menu_button_label')}
        onMouseEnter={onUserHover}
        onMouseLeave={onUserLeave}
        className='user-badge-button'
      >
        <span className='user-badge'>{userData.name.initials}</span>
      </button>
      {showUserInfo && (
        <div
          className='user-info-menu'
          onMouseEnter={onUserHover}
          onMouseLeave={onUserLeave}
        >
          <div className='user-info-container'>
            <p className='user-name'>{userData.name.fullName}</p>
          </div>
          <div className='action-list-wrapper'>
            <SettingsBadge onGotoUserSettings={gotoUserSettings} />
          </div>
          <button
            data-metrics-id='UserLogoutButton'
            onClick={logout}
            className='user-logout-button'
          >
            {t('user_menu.logout')}
          </button>
        </div>
      )}
    </div>
  );
};
