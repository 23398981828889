import './Chat.scss';
import { useAppContext } from '../../state/useAppProvider';
import { ChatMessage, Conversation } from '../../types';
import { Message } from '../../components/chat/message/Message';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorPopupHandler } from '../../components/error-popup-handler/ErrorPopupHandler';
import { MessageLoader } from '../../components/chat/message-loader';
import { useApiContext } from '../../api/useApiContext';
import { ImageAnalysisContainer } from '../../components/chat/image-upload-panel/ImageAnalysisContainer';
import { debounce } from 'lodash-es';
import { ChatLoader } from '../../components/chat/chat-loader';
import { useParams } from 'react-router-dom';
import { useMessageHandlerContext } from '../../state/useMessageHandlerContext';
import { useTranslation } from 'react-i18next';
import { ProductTagList } from '../../components/chat/product-selector/product-selection-menu/ProductTagList';
import { ChatActionPanel } from '../../components/chat/chat-action-panel';
import { ProductDocumentPanel } from '../../components/chat/product-document-panel';
import { useNavigateWithTracker } from '../../hooks/use-navigate-with-tracker';
import { Pages } from '../constants/pages';
import { useProductHandler } from '../../state/useProductHandler';
import { ProductMessage } from '../../components/chat/message';
import { ProductSelector } from '../../components/chat/product-selector';

const AuthenticationErrorPanel = () => {
  const { t } = useTranslation();
  return (
    <article className='chat-empty-state'>
      <h1 className='chat-empty-state-title'>
        {t('chat_window.error_panel.title')}
      </h1>
      <p className='chat-empty-state-subtitle'>
        {t('chat_window.error_panel.subtitle')}
      </p>
    </article>
  );
};

const EmptyChatPanel = () => {
  const { t } = useTranslation();
  return (
    <div className='empty-chat-container'>
      <img
        className='empty-chat-img'
        src='/icons/chat.svg'
        aria-hidden='true'
      />
      <div className='empty-chat-text'>
        <h1 className='empty-chat-header'>
          {t('chat_window.empty_chat.title')}
        </h1>
        <h2 className='empty-chat-ingress'>
          {t('chat_window.empty_chat.subtitle')}
        </h2>
      </div>
    </div>
  );
};

export const ChatWindow = () => {
  const { t } = useTranslation();
  const { dispatch, currentChat, pendingMessage, imageToAnalyze } =
    useAppContext();
  const { isLoading, showAuthMessage, historyRead } = useApiContext();
  const messages = useMemo(
    () => currentChat.messages,
    [currentChat.messages],
  );
  const isAnalyzingImage = imageToAnalyze && imageToAnalyze.length > 0;
  const showEmptyMessage =
    messages.length < 1 && !pendingMessage && !isAnalyzingImage;
  const { conversationId } = useParams();
  const currentConversationId = useRef<string>('');
  const { loadingState, addNewMessage,  createNewChat, initializeConversation } =
    useMessageHandlerContext();
  const { setProductDataFromFilter } = useProductHandler();
  const navigate = useNavigateWithTracker();
  const [pendingMessageAdded, setPendingMessageAdded] = useState(false);

  const updateScrollPosition = useMemo(
    () =>
      debounce(() => {
        const latestMessage = document.querySelector(
          '.chat-message-stream > div:last-child',
        );
        if (!latestMessage) return;
        latestMessage.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }, 50),
    [],
  );

  const handlePromptSubmit = (message: ChatMessage) => {
    addNewMessage(message);
    updateScrollPosition();
  };

  useEffect(() => {
    if (currentChat.id && currentChat.messages.length > 0 && !conversationId) {
      navigate(Pages.CHAT, `/chat/${currentChat.id}`);
    } else if (!currentChat.id && !conversationId) {
      createNewChat();
    }
  }, [
    conversationId,
    createNewChat,
    currentChat.id,
    currentChat.messages.length,
    navigate,
  ]);

  useEffect(() => {
    if (pendingMessage?.messageContent.content) {
      setPendingMessageAdded(true);
    } else {
      setPendingMessageAdded(false);
    }
  }, [pendingMessage?.messageContent.content]);

  //Scroll down when new messages are added.
  useEffect(() => {
    if (pendingMessageAdded || messages.length > 1) {
      updateScrollPosition();
    }
  }, [messages, pendingMessageAdded, updateScrollPosition]);

  //Fetch chat history from current URL path if it's not already loaded.
  useEffect(() => {
    if (!conversationId) {
      currentConversationId.current = '';
    } else if (
      conversationId !== currentConversationId.current &&
      !currentChat.id
    ) {
      currentConversationId.current = conversationId;
      historyRead(conversationId, async (conversation: Conversation) => {
        // Set the product data on initial load to avoid empty product messages.
        if (conversation.data_filter) {
          await setProductDataFromFilter(conversation.data_filter);
        }

        await initializeConversation(conversation);

        dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: conversation,
        });
      });
    }
  }, [
    dispatch,
    conversationId,
    historyRead,
    currentChat.id,
    setProductDataFromFilter,
  ]);

  if (isLoading) return <ChatLoader message={t('chat_window.loader')} />;

  return showAuthMessage ? (
    <AuthenticationErrorPanel />
  ) : (
    <div className='chat-window'>
      <ErrorPopupHandler />
      {showEmptyMessage ? (
        <EmptyChatPanel />
      ) : (
        <div className='chat-message-viewport'>
          <div className='chat-message-stream-head'>
            <ProductTagList />
          </div>
          <div id='messages-viewport' className='chat-message-stream'>
            {messages.map((message, index) => {
              switch (message.role) {
                case 'interaction':
                  return (
                    <ProductMessage key={index} choices={message.choices} />
                  );
                case 'user':
                case 'assistant':
                  return <Message key={index} messageContent={message} />;
              }
            })}
            {pendingMessage && <Message {...pendingMessage} />}
            {loadingState.loading && (
              <MessageLoader message={loadingState.message} />
            )}
            <ProductSelector />
            <ImageAnalysisContainer />
            {/* Bottom anchor used for scrolling to bottom */}
            <div id='bottom-scroll-anchor'></div>
          </div>
          <ProductDocumentPanel />
        </div>
      )}

      <ChatActionPanel messageSubmitCallback={handlePromptSubmit} />
    </div>
  );
};
