export const MenuIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
    >
      <path d='M180-269.231q-8.5 0-14.25-5.757-5.75-5.758-5.75-14.27 0-8.511 5.75-14.242 5.75-5.731 14.25-5.731h600q8.5 0 14.25 5.758t5.75 14.269q0 8.512-5.75 14.243-5.75 5.73-14.25 5.73H180ZM180-460q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q171.5-500 180-500h600q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q788.5-460 780-460H180Zm0-190.769q-8.5 0-14.25-5.758T160-670.796q0-8.512 5.75-14.243 5.75-5.73 14.25-5.73h600q8.5 0 14.25 5.757 5.75 5.758 5.75 14.27 0 8.511-5.75 14.242-5.75 5.731-14.25 5.731H180Z' />
    </svg>
  );
};
