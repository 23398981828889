import { useAppContext } from '../../../../state/useAppProvider';
import { useProductHandler } from '../../../../state/useProductHandler';
import './ProductTagList.scss';

export const ProductTagList = () => {
  const { showProductTags } = useAppContext();
  const { selectedProducts } = useProductHandler();

  return (
    selectedProducts && (
      <div data-visible={showProductTags} className='product-tag-list'>
        {selectedProducts.map((product, key) => (
          <div key={key} className='product-tag'>
            {product.display_name}
          </div>
        ))}
      </div>
    )
  );
};
