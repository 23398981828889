import { useTranslation } from 'react-i18next';
import { useProductHandler } from '../../../state/useProductHandler';
import { ConversationState, Product } from '../../../types';
import { useEffect, useRef } from 'react';
import { useAppContext } from '../../../state/useAppProvider';
import './ProductMessage.scss';
import { AddProductButton } from '../product-selector/add-product-prompt/AddProductButton';

export const ProductMessage = ({ choices }: { choices: Product[] }) => {
  const { getProductSubheader, canAddNewProduct } = useProductHandler();
  const { t } = useTranslation();
  const productChoiceRef = useRef<HTMLDivElement>(null);
  const { dispatch, conversationState } = useAppContext();

  //Hide/show product tags when product choice message goes in and out of view.
  useEffect(() => {
    if (!productChoiceRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          dispatch({ type: 'SET_SHOW_PRODUCT_TAGS', payload: true });
        } else {
          dispatch({ type: 'SET_SHOW_PRODUCT_TAGS', payload: false });
        }
      },
      { root: document.querySelector('#messages-viewport') },
    );
    observer.observe(productChoiceRef.current);

    return () => observer.disconnect();
  }, [dispatch]);

  return (
    <div className='product-message-wrapper'>
      <div ref={productChoiceRef} className='product-message-container'>
        <h4 className='product-choice-message'>
          {t('messages.product_choice.choice_prefix')}
        </h4>
        <div className='product-selection-list'>
          {choices.map((product) => (
            <div
              key={Object.values(product).join('-')}
              className='product-badge'
            >
              <h4 className='product-badge-subheader'>
                {getProductSubheader(product)}
              </h4>
              <h5 className='product-badge-header'>{product.display_name}</h5>
            </div>
          ))}
        </div>
      </div>
      {canAddNewProduct &&
        conversationState !== ConversationState.PRODUCT_SELECTION && (
          <>
            <div className='product-message-border-line'></div>
            <AddProductButton />
          </>
        )}
    </div>
  );
};
