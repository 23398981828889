import { useProductHandler } from '../../../../state/useProductHandler';
import './AddProductPrompt.scss';
import { AddProductButton } from './AddProductButton';

export const AddProductPrompt = () => {
  const { canAddNewProduct } = useProductHandler();

  if (!canAddNewProduct) return null;

  return (
    <div className='add-product-prompt'>
      <div className='add-product-prompt-border'></div>
      <AddProductButton />
      <div className='add-product-prompt-border'></div>
    </div>
  );
};
