import { useEffect } from 'react';
import { useIsTestEnv } from '../../hooks/use-is-test-env';
import { useMsal } from '@azure/msal-react';
import './DocumentTitle.scss';

export const DocumentTitle = () => {
  const isTestEnv = useIsTestEnv();
  const { inProgress } = useMsal();

  useEffect(() => {
    const updateHeaderTitle = () => {
      const headerTitle = document.querySelector('.header-title');
      
      if (headerTitle && isTestEnv) {
        headerTitle.innerHTML = 'Technician CoPilot <span class="test-environment-text">- Test Environment</span>';
      } else if (headerTitle) {
        headerTitle.innerHTML = 'Technician CoPilot';
      }

      document.title = 'ASSA ABLOY - Technician CoPilot';
    };

    updateHeaderTitle();
    const intervalId = setInterval(updateHeaderTitle, 1000);

    return () => clearInterval(intervalId);
  }, [isTestEnv, inProgress]);

  return null;
};
