import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../chat/feedback-panel/CheckIcon';
import './InformationMenu.scss';

interface RequestFormSuccessProps {
  hideMessage: boolean;
}

export const RequestFormSuccess = ({ hideMessage }: RequestFormSuccessProps) => {
  const { t } = useTranslation();
  return (
    <div className={`masking-wrapper ${hideMessage && 'hidden'}`}>
      <div className={`request-form-complete ${hideMessage && 'hidden'}`}>
        <CheckIcon className='feedback-check' />
        <div>
          <h2 className='feedback-panel-form-heading'>
            {t('information_menu.request_form.success.title')}
          </h2>
          <p className='feedback-panel-complete-message'>
            {t('information_menu.request_form.success.message')}
          </p>
        </div>
      </div>
    </div>
  );
};