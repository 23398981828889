import { useCallback, useEffect } from 'react';
import { useWindowSize } from './use-window-width';

export const useDynamicInputHeight = (
  textareaRef: React.RefObject<HTMLTextAreaElement>,
) => {
  const { isMobile } = useWindowSize();

  const updateTextAreaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const { scrollHeight } = textareaRef.current;
      if (scrollHeight < 200) {
        textareaRef.current.style.overflowY = 'hidden';
      } else {
        textareaRef.current.style.overflowY = 'scroll';
      }
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef]);

  useEffect(() => {
    if (!textareaRef.current) return;
    const input = textareaRef.current;

    input.addEventListener('input', updateTextAreaHeight);

    return () => input.removeEventListener('input', updateTextAreaHeight);
  }, [textareaRef, updateTextAreaHeight]);

  useEffect(() => {
    updateTextAreaHeight();
  }, [isMobile, updateTextAreaHeight]);
};
