export const ThumbsDownIcon = ({ selected }: { selected?: boolean }) => {
  return selected ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      fill='#0393be'
    >
      <path d='M261.538-767.693h392.308v440.001L403.077-80l-17.693-17.692q-5.461-5.462-9.192-14-3.731-8.539-3.731-15.693v-6.307l40.923-194H144.615q-25.077 0-44.846-19.77-19.77-19.769-19.77-44.846v-49.231q0-5.461 1.116-11.923 1.116-6.461 3.346-11.923l109.231-258.923q8.231-18.462 27.692-30.923 19.462-12.462 40.154-12.462Zm432.308 440.001v-440.001H840v440.001H693.846Z' />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      fill='#1a1a1a'
    >
      <path d='M261.538-767.693h401.539v440.001L412.308-80l-17.693-17.692q-5.461-5.462-9.192-14-3.731-8.539-3.731-15.693v-6.307l40.923-194h-278q-25.077 0-44.846-19.77-19.77-19.769-19.77-44.846v-49.231q0-5.461 1.232-11.923 1.23-6.461 3.23-11.923l109.231-258.923q8.231-18.462 27.692-30.923 19.462-12.462 40.154-12.462Zm361.539 40H261.538q-8.461 0-17.307 4.616-8.846 4.615-13.462 15.384L120-447.693v55.385q0 10.769 6.923 17.692t17.692 6.923h327.693L426-149.231l197.077-195.538v-382.924Zm0 382.924v-382.924 382.924Zm40 17.077v-40.001H800v-360H663.077v-40H840v440.001H663.077Z' />
    </svg>
  );
};
