import axios from 'axios';

export const axiosWithToken = (tokenFetcher: () => Promise<string | void>) => {
  const axiosWithToken = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
  });
  axiosWithToken.interceptors.request.use(
    async (config) => {
      const token = await tokenFetcher();
      if (!token) {
        throw new Error('Could not get the ID token token');
      }
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

  return axiosWithToken;
};
