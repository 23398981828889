import './Message.scss';

import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';
import {
  AskResponse,
  Citation,
  MessageData,
  MessageType,
} from '../../../types';
import { parseAnswer } from '../../../helpers/answer-parser';
import { AnswerReferences } from './AnswerReferences';
import { FeedbackPanel } from '../feedback-panel/FeedbackPanel';
import { useAppContext } from '../../../state/useAppProvider';
import { ImageAnalysisContainer } from '../image-upload-panel/ImageAnalysisContainer';
import { SpeechToTextIcon } from './SpeechToTextIcon';
import { CancelIcon } from './CancelIcon';
import { MessageLoader } from '../message-loader';
import { SpeechRecorder } from '../speech-recorder';
import { useTranslation } from 'react-i18next';

const SpeechMessage = ({
  message,
  loading,
  onCancel,
}: {
  message: string;
  loading?: boolean;
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className='chat-message-user-container'>
      <div className='chat-message-user-wrapper'>
        <SpeechToTextIcon className='speech-to-text-icon' />
        <div className='chat-message-speech-message'>
          {loading ? (
            <MessageLoader message={t('messages.speech_message.starting')} />
          ) : (
            <SpeechRecorder
              message={
                message.length > 0
                  ? message
                  : t('messages.speech_message.listening')
              }
            />
          )}
        </div>
        <button
          data-metrics-id='CancelSpeechButton'
          onClick={onCancel}
          className='cancel-speech-button'
        >
          <CancelIcon />
          {t('messages.speech_message.cancel')}
        </button>
      </div>
    </div>
  );
};

const TextMessage = ({ message }: { message: string }) => {
  return (
    <div className='chat-message-user-container'>
      <div className='chat-message-user-wrapper'>
        <div className='chat-message-user-message'>{message}</div>
      </div>
    </div>
  );
};

type UserMessageProps = {
  message: string;
  type: MessageType;
  loading?: boolean;
  onCancel?: () => void;
};

const UserMessage = ({
  message,
  type,
  loading,
  onCancel,
}: UserMessageProps) => {
  switch (type) {
    case 'speech': {
      return (
        <SpeechMessage
          loading={loading}
          onCancel={onCancel}
          message={message}
        />
      );
    }
    case 'text': {
      return <TextMessage message={message} />;
    }
    //TODO: Update logic for image message type.
    case 'image': {
      <ImageAnalysisContainer />;
    }
  }
};

const BotMessage = ({
  answer,
  citations,
  messageId,
}: {
  answer: string;
  citations?: Citation[];
  messageId: string;
}) => {
  const formattedResponse: AskResponse = { citations: citations ?? [], answer };
  const parsedAnswer = parseAnswer(formattedResponse);
  // TODO: Pass conversationId from chat window when support for multiple conversation exists.
  const { currentChat } = useAppContext();

  return (
    <div className='chat-message-bot-wrapper'>
      <div className='chat-message-bot-container'>
        <ReactMarkdown
          remarkPlugins={[remarkGfm, supersub]}
          rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
          children={parsedAnswer.markdownFormatText}
          className='chat-message-bot-answer'
        />
        {currentChat.id && (
          <FeedbackPanel
            messageId={messageId}
            conversationId={currentChat.id}
          />
        )}
        {parsedAnswer.citations.length > 0 && (
          <AnswerReferences references={parsedAnswer.citations} />
        )}
      </div>
    </div>
  );
};

export const Message = ({
  messageContent,
  type = 'text',
  loading,
  onCancel,
}: MessageData) => {
  const { role, content, tool_message, id } = messageContent;
  switch (role) {
    case 'user': {
      return (
        <UserMessage
          loading={loading}
          onCancel={onCancel}
          type={type}
          message={content}
        />
      );
    }
    case 'assistant': {
      return (
        <BotMessage
          messageId={id}
          answer={content}
          citations={tool_message?.citations}
        />
      );
    }
  }
};
