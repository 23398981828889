import { CSSProperties } from 'react';
import './MessageLoader.scss';
import * as loaderData from '../../../animations/loader.json';
import Lottie from 'react-lottie-player';

const messageLoaderStyles: CSSProperties = {
  height: '60px',
  width: '60px',
  flexShrink: '0',
};

export const MessageLoader = ({ message }: { message: string }) => {
  return (
    <div className='message-loader'>
      <Lottie
        animationData={loaderData}
        play
        loop
        style={messageLoaderStyles}
      />
      <h4 className='message-loader-text'>{message}</h4>
    </div>
  );
};
