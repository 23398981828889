import { Trans } from 'react-i18next';
import { useMessageHandlerContext } from '../../../state/useMessageHandlerContext';
import { ChatMessage, ConversationState } from '../../../types';
import { PromptInput } from '../prompt_input/PromptInput';
import './ChatActionPanel.scss';
import { CloseIcon } from '../../error-popup-handler/CloseIcon';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../state/useAppProvider';

export const ChatActionPanel = ({
  messageSubmitCallback,
}: {
  messageSubmitCallback: (message: ChatMessage) => void;
}) => {
  const { loadingState } = useMessageHandlerContext();
  const { currentChat, conversationState } = useAppContext();
  const [hideDisclaimer, setHideDisclaimer] = useState(true);
  const submitDisabled =
    loadingState.loading ||
    conversationState === ConversationState.PRODUCT_SELECTION;

  useEffect(() => {
    if (currentChat.messages.length > 0) {
      setHideDisclaimer(true);
    } else if (currentChat.messages.length === 0) {
      setHideDisclaimer(false);
    }
  }, [currentChat.messages]);

  return (
    <div className='chat-action-panel'>
      <PromptInput onSubmit={messageSubmitCallback} disabled={submitDisabled} />
      <div className='chat-disclaimer-wrapper'>
        <div
          className='chat-disclaimer-container'
          data-collapsed={hideDisclaimer}
        >
          <button
            data-metrics-id='CloseDisclaimerButton'
            onClick={() => setHideDisclaimer(true)}
            aria-label='Close disclaimer text'
            className='chat-disclaimer-button'
          >
            <CloseIcon />
          </button>
          <p className='chat-disclaimer'>
            <Trans i18nKey='chat_window.input.disclaimer'>
              Technician CoPilot may display inaccurate AI-generated content,
              please always verify responses and consider the
              <a
                data-metrics-id='SafetyPolicyLink'
                className='chat-disclaimer-link'
                href='https://avenue.assaabloy.net/group-library/health-and-safety'
                target='_blank'
              >
                ASSA ABLOY Safety policy, directive and guidelines.
              </a>
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};
