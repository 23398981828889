import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../api/useAuthContext';
import { useApiContext } from '../../api/useApiContext';
import { RequestFormSuccess } from './RequestFormSuccess';
import { useAppContext } from '../../state/useAppProvider';

interface IncidentReportFormProps {
  onClose: () => void;
}

export const IncidentReportForm = ({ onClose }: IncidentReportFormProps) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { createIncidentReport } = useApiContext();
  const { currentChat } = useAppContext();

  const [formData, setFormData] = useState({
    reason: '',
    description: ''
  });
  const [errors, setErrors] = useState({
    reason: false,
    description: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [hideSubmitMessage, setHideSubmitMessage] = useState(false);
  const [includeEmail, setIncludeEmail] = useState(true);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const newErrors = {
      reason: !formData.reason,
      description: !formData.description
    };
    
    setErrors(newErrors);
    
    if (!newErrors.reason && !newErrors.description) {
      setIsSubmitting(true);
      try {
        await createIncidentReport({
          email: includeEmail ? (userData?.email || '') : '',
          reason: formData.reason,
          description: formData.description,
          conversation_id: currentChat?.id || undefined
        });
        setSubmitted(true);
        setTimeout(() => {
          setHideSubmitMessage(true);
          setTimeout(() => onClose(), 300);
        }, 4000);
      } catch (error) {
        console.error('Failed to submit incident report:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (submitted) {
    return <RequestFormSuccess hideMessage={hideSubmitMessage} />;
  }

  return (
    <div className="request-form-container incident-form">
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label>{t('information_menu.incident_form.reason')}</label>
          <input
            type="text"
            className={errors.reason ? 'error' : ''}
            value={formData.reason}
            onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
            placeholder={t('information_menu.incident_form.reason_placeholder')}
          />
          {errors.reason && (
            <span className="error-message">
              {t('information_menu.incident_form.reason_required')}
            </span>
          )}
        </div>

        <div className="form-field">
          <label>{t('information_menu.incident_form.description')}</label>
          <textarea
            className={errors.description ? 'error' : ''}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            placeholder={t('information_menu.incident_form.description_placeholder')}
            rows={4}
          />
          {errors.description && (
            <span className="error-message">
              {t('information_menu.incident_form.description_required')}
            </span>
          )}
        </div>

        <div className="form-field">
          <div className="email-consent-field">
            <input
              type="checkbox"
              id="include-email"
              checked={includeEmail}
              onChange={(e) => setIncludeEmail(e.target.checked)}
            />
            <label htmlFor="include-email" className="checkbox-label">
              {t('information_menu.incident_form.include_email_label', {
                email: userData?.email
              })}
            </label>
          </div>
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            onClick={onClose} 
            disabled={isSubmitting}
            className="cancel-button"
          >
            {t('common.cancel')}
          </button>
          <button 
            type="submit" 
            disabled={isSubmitting}
            className={`submit-button ${isSubmitting ? 'loading' : ''}`}
          >
            {t('common.send')}
          </button>
        </div>
      </form>
    </div>
  );
};
