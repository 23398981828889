import { HTMLAttributes } from 'react';

export const SpeechToTextIcon = ({ ...props }: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      {...props}
    >
      <path d='M660.15-564.615q-24.688 0-42.419-17.25Q600-599.115 600-624.615V-800q0-25.5 17.633-42.75T660.027-860q25.512 0 42.742 17.25Q720-825.5 720-800v175.385q0 25.5-17.207 42.75t-42.643 17.25ZM224.615-120q-26.654 0-45.634-18.981Q160-157.961 160-184.615v-590.77q0-26.654 18.981-45.634Q197.961-840 224.615-840H480q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269 0 8.511-5.75 14.242Q488.5-800 480-800H224.615q-10.769 0-17.692 6.923T200-775.385v590.77q0 10.769 6.923 17.692T224.615-160h390.77q10.769 0 17.692-6.923T640-184.615v-46.154q0-8.5 5.758-14.25t14.269-5.75q8.512 0 14.242 5.75 5.731 5.75 5.731 14.25v46.154q0 26.654-18.981 45.634Q642.039-120 615.385-120h-390.77Zm290.77-130.769h-190.77q-10.461 0-17.538-7.077T300-275.385q0-10.461 7.077-17.538T324.615-300h190.77q10.461 0 17.538 7.077T540-275.385q0 10.462-7.077 17.539-7.077 7.077-17.538 7.077Zm-75.385-120H320q-8.5 0-14.25-5.758T300-390.796q0-8.512 5.75-14.243 5.75-5.73 14.25-5.73h120q8.5 0 14.25 5.757 5.75 5.758 5.75 14.27 0 8.511-5.75 14.242-5.75 5.731-14.25 5.731ZM659.719-480q59.497 0 104.12-34.039 44.622-34.038 54.007-87.807 1.923-9.308 7.876-16.039 5.952-6.73 14.384-6.73 8.433 0 14.163 6.961 5.731 6.962 4.039 16.269-8.615 66-59.192 109.924Q748.539-447.538 680-440.923V-360q0 8.5-5.758 14.25-5.757 5.75-14.269 5.75-8.511 0-14.242-5.75Q640-351.5 640-360v-80.923q-67.538-6.615-118.231-50.538-50.692-43.924-60.077-108.385-1.692-10.077 3.654-17.423 5.346-7.346 14.27-7.346 8.923 0 14.769 6.73 5.846 6.731 7.769 16.039 9.385 53.769 54.346 87.807Q601.462-480 659.719-480Z' />
    </svg>
  );
};
