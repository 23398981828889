import { useAppContext } from '../../../state/useAppProvider';
import { CSSProperties } from 'react';
import './ImageAnalysisContainer.scss';
import * as loaderData from '../../../animations/loader.json';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';

const imageLoaderStyles: CSSProperties = {
  height: '150px',
  width: '150px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export const ImageAnalysisContainer = () => {
  const { t } = useTranslation();
  const { dispatch, imageToAnalyze: uploadedImage } = useAppContext();

  const removeUploadedImage = () => {
    dispatch({ type: 'SET_IMAGE_TO_ANALYZE', payload: '' });
  };

  if (!uploadedImage || uploadedImage.length === 0) return null;

  return (
    <div className='image-container'>
      <Lottie animationData={loaderData} play loop style={imageLoaderStyles} />
      <h4 className='image-heading'>{t('messages.image_message.analyzing')}</h4>
      <img className='image-thumbnail' src={uploadedImage} />
      <button
        data-metrics-id='CancelImageUploadButton'
        onClick={() => removeUploadedImage()}
      >
        {t('messages.image_message.remove')}
      </button>
    </div>
  );
};
