export const AddProductIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24px'
      viewBox='0 -960 960 960'
      width='24px'
      fill='#5f6368'
    >
      <path d='M200-643.85h349.92H524.23h13.23H200Zm420 0h140-140Zm-404.38-59.99H744l-43.62-51.93q-1.92-1.92-4.42-3.08-2.5-1.15-5.19-1.15H268.85q-2.69 0-5.2 1.15-2.5 1.16-4.42 3.08l-43.61 51.93ZM400-445.77l80-40 80 40v-198.08H400v198.08ZM212.31-140q-29.83 0-51.07-21.24Q140-182.48 140-212.31v-467.46q0-12.65 4.12-24.4 4.11-11.75 12.34-21.6l56.16-67.92q9.84-12.7 24.61-19.5Q252-820 268.52-820h422.19q16.52 0 31.43 6.81 14.92 6.8 24.86 19.5L803.54-725q8.23 10.01 12.34 21.83 4.12 11.82 4.12 24.56v143.3q0 12.75-8.63 21.38-8.63 8.62-21.38 8.62-12.76 0-21.37-8.62-8.62-8.63-8.62-21.38v-108.54H620v179.54q-23.08 13.85-41.58 35.08-18.5 21.23-28.5 45.92L480-418.08l-87.77 43.69q-18.07 9.23-35.15-1.21Q340-386.04 340-406.92v-236.93H200v431.54q0 5.39 3.46 8.85t8.85 3.46h307.54q12.75 0 21.37 8.58 8.63 8.57 8.63 21.25 0 11.94-8.63 21.05-8.62 9.12-21.37 9.12H212.31Zm549.6 11.92q-12.76 0-21.37-8.62-8.62-8.63-8.62-21.38v-90h-90q-12.75 0-21.37-8.63-8.63-8.62-8.63-21.38t8.63-21.37q8.62-8.62 21.37-8.62h90v-90q0-12.75 8.63-21.37 8.63-8.63 21.39-8.63 12.75 0 21.37 8.63 8.61 8.62 8.61 21.37v90h90q12.75 0 21.38 8.63 8.62 8.63 8.62 21.39 0 12.75-8.62 21.37-8.63 8.61-21.38 8.61h-90v90q0 12.75-8.63 21.38-8.62 8.62-21.38 8.62ZM200-643.85h349.92-25.69 13.23H200Z' />
    </svg>
  );
};
