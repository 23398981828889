import { AskResponse, Citation } from '../types';

type ParsedAnswer = {
  citations: Citation[];
  markdownFormatText: string;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {
  let answerText = answer.answer;
  const citationMarkers = answerText.match(/\[(doc\d\d?\d?)]/g);
  const lengthDocN = '[doc'.length;

  const addedIndexes: string[] = [];
  const indexedCitations: Citation[] = [];
  citationMarkers?.forEach((marker, index) => {
    const citationIndex = marker.slice(lengthDocN, marker.length - 1);
    const reindex_id = (index + 1).toString();
    if (!addedIndexes.find((idx) => idx === citationIndex)) {
      answerText = answerText.replaceAll(marker, ` ^[${reindex_id}]^ `);
      addedIndexes.push(citationIndex);

      const parsedMarker = marker.slice(1, marker.length - 1);
      const citationByRef = answer.citations.find((ref) => ref.refkey === parsedMarker); // citation in v2 conversation contains refkey
      const indexedCitation = citationByRef ? citationByRef : answer.citations[parseInt(citationIndex) - 1]; // choose citation by index in v1 conversation as fallback. Note that we are not sure that all citations are included in the answer
       
      if (indexedCitation) {
        indexedCitation.id = citationIndex;
        indexedCitation.reindex_id = reindex_id;
        indexedCitations.push(indexedCitation);
      }
    }
  });

  return {
    citations: indexedCitations,
    markdownFormatText: answerText,
  };
}
