import { useAuthContext } from '../../api/useAuthContext';
import './AccountSetupPage.scss';
import { Name, UserSettings } from '../../types';
import { ChatLoader } from '../../components/chat/chat-loader';
import { UserSetupWizard } from '../../components/user-setup-wizard';
import { Link } from 'react-router-dom';

export const AccountSetupPage = () => {
  const { userSetupData, addUser, loadingUser, userSettings } =
    useAuthContext();

  if (loadingUser) return <ChatLoader message='Waiting for user data...' />;

  const onSave = (id: string, name: Name, setupFormSettings: UserSettings) => {
    addUser(id, name, setupFormSettings);
  };

  return (
    <div id='account-setup-wrapper'>
      <div id='account-setup-info'>
        <div className='account-setup-header'>
          <h1 className='account-setup-subheader'>
            ASSA ABLOY - Technician CoPilot
          </h1>
          <h2 className='account-setup-heading'>First time setup</h2>
        </div>
        {userSetupData ? (
          <UserSetupWizard userSetupData={userSetupData} onSave={onSave} />
        ) : (
          <div className='account-setup-error'>
            {!userSettings ? (
              <div className='account-setup-error-container'>
                <h2 className='account-setup-error-heading'>
                  Failed to load user data
                </h2>
                <p className='account-setup-error-message'>
                  Please reload the page or contact your administrator for
                  assistance.
                </p>
              </div>
            ) : (
              <div className='account-setup-error-container'>
                <h2 className='account-setup-error-heading'>
                  User already setup
                </h2>
                <p className='account-setup-error-message'>
                  You have already completed the setup process.
                </p>
                <Link className='link-button' to='/'>
                  Return to home
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
