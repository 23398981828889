import { useCallback, useState } from 'react';
import { ChatLoadingState } from '../types';

type SetChatLoadingState = (loading: boolean, message?: string) => void;

export const useLoadingState = (
  initialState: ChatLoadingState = { loading: false, message: '' },
): [ChatLoadingState, SetChatLoadingState] => {
  const [isLoading, setIsLoading] = useState(initialState.loading);
  const [loadingMessage, setLoadingMessage] = useState(initialState.message);
  const loadingState: ChatLoadingState = {
    loading: isLoading,
    message: loadingMessage,
  };

  const setLoadingState: SetChatLoadingState = useCallback(
    (loading: boolean, message?: string) => {
      setIsLoading(loading);
      setLoadingMessage(message ?? '');
    },
    [],
  );

  return [loadingState, setLoadingState];
};
