import { CSSProperties } from 'react';
import './SpeechRecorder.scss';
import * as speechData from '../../../animations/speech.json';
import Lottie from 'react-lottie-player';

const messageLoaderStyles: CSSProperties = {
  height: '60px',
  width: '60px',
  flexShrink: '0',
  margin: '-16px 4px -16px -16px',
};

export const SpeechRecorder = ({ message }: { message: string }) => {
  return (
    <div className='speech-loader'>
      <Lottie
        animationData={speechData}
        play
        loop
        style={messageLoaderStyles}
      />
      <h4 className='speech-loader-text'>{message}</h4>
    </div>
  );
};
