export const FilterIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
    >
      <path d='M479.973-140q-8.512 0-14.242-5.75Q460-151.5 460-160v-160q0-8.5 5.758-14.25 5.757-5.75 14.269-5.75t14.242 5.75Q500-328.5 500-320v60h300q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q808.5-220 800-220H500v60q0 8.5-5.758 14.25-5.757 5.75-14.269 5.75ZM160-220q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q151.5-260 160-260h160q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q328.5-220 320-220H160Zm159.973-160q-8.512 0-14.242-5.75Q300-391.5 300-400v-60H160q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q151.5-500 160-500h140v-60q0-8.5 5.758-14.25 5.757-5.75 14.269-5.75t14.242 5.75Q340-568.5 340-560v160q0 8.5-5.758 14.25-5.757 5.75-14.269 5.75ZM480-460q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q471.5-500 480-500h320q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q808.5-460 800-460H480Zm159.973-160q-8.512 0-14.242-5.75Q620-631.5 620-640v-160q0-8.5 5.758-14.25 5.757-5.75 14.269-5.75t14.242 5.75Q660-808.5 660-800v60h140q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q808.5-700 800-700H660v60q0 8.5-5.758 14.25-5.757 5.75-14.269 5.75ZM160-700q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q151.5-740 160-740h320q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q488.5-700 480-700H160Z' />
    </svg>
  );
};
