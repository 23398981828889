export const ChatIcon = ({ active }: { active?: boolean }) => {
  return active ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
      fill='#fff'
    >
      <path d='M774.692-260H600q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q591.5-300 600-300h174.692l-68.846-69.615q-5.359-5.846-5.603-13.885-.243-8.039 5.603-13.885t14.014-5.846q8.168 0 14.294 5.846l94.769 94.77q9.693 9.692 9.693 22.615 0 12.923-9.693 22.615l-94.769 94.77q-6.126 5.846-14.294 5.846t-14.014-5.846q-5.846-5.846-5.603-13.885.244-8.039 5.603-13.885L774.692-260ZM304.615-580h270.77q8.5 0 14.25-5.758 5.75-5.757 5.75-14.269t-5.75-14.242q-5.75-5.731-14.25-5.731h-270.77q-8.5 0-14.25 5.758-5.75 5.757-5.75 14.269t5.75 14.242q5.75 5.731 14.25 5.731Zm0 160h150.77q8.5 0 14.25-5.758 5.75-5.757 5.75-14.269t-5.75-14.242q-5.75-5.731-14.25-5.731h-150.77q-8.5 0-14.25 5.758-5.75 5.757-5.75 14.269t5.75 14.242q5.75 5.731 14.25 5.731Zm-73.076 160-63.949 63.949q-7.436 7.436-17.513 3.558Q140-196.372 140-207.231v-508.154q0-26.654 18.981-45.634Q177.961-780 204.615-780h470.77q26.654 0 45.634 18.981Q740-742.039 740-715.385v173.231q0 9.949-6.577 16.167-6.577 6.218-16.269 5.987-49.462 1.077-90.962 15.115-41.5 14.039-79.269 51.808-37.538 37.769-51.692 79.269-14.154 41.5-15.231 90.962.231 9.692-6.402 16.269Q466.966-260 457.846-260H231.539Z' />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      viewBox='0 -960 960 960'
      width='24'
    >
      <path d='M774.692-260H600q-8.5 0-14.25-5.758-5.75-5.757-5.75-14.269t5.75-14.242Q591.5-300 600-300h174.692l-68.846-69.615q-5.359-5.846-5.603-13.885-.243-8.039 5.603-13.885t14.014-5.846q8.168 0 14.294 5.846l94.769 94.77q9.693 9.692 9.693 22.615 0 12.923-9.693 22.615l-94.769 94.77q-6.126 5.846-14.294 5.846t-14.014-5.846q-5.846-5.846-5.603-13.885.244-8.039 5.603-13.885L774.692-260Zm-543.153 0-63.949 63.949q-7.436 7.436-17.513 3.558Q140-196.372 140-207.231v-508.154q0-26.654 18.981-45.634Q177.961-780 204.615-780h470.77q26.654 0 45.634 18.981Q740-742.039 740-715.385V-540q0 8.5-5.758 14.25-5.757 5.75-14.269 5.75t-14.242-5.75Q700-531.5 700-540v-175.385q0-10.769-6.923-17.692T675.385-740h-470.77q-10.769 0-17.692 6.923T180-715.385V-300h280q8.5 0 14.25 5.758 5.75 5.757 5.75 14.269t-5.75 14.242Q468.5-260 460-260H231.539Zm73.076-320h270.77q8.5 0 14.25-5.758 5.75-5.757 5.75-14.269t-5.75-14.242q-5.75-5.731-14.25-5.731h-270.77q-8.5 0-14.25 5.758-5.75 5.757-5.75 14.269t5.75 14.242q5.75 5.731 14.25 5.731Zm0 160h150.77q8.5 0 14.25-5.758 5.75-5.757 5.75-14.269t-5.75-14.242q-5.75-5.731-14.25-5.731h-150.77q-8.5 0-14.25 5.758-5.75 5.757-5.75 14.269t5.75 14.242q5.75 5.731 14.25 5.731ZM180-300v-440 440Z' />
    </svg>
  );
};
