import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { To, NavigateOptions, useNavigate } from 'react-router-dom';
import { Pages } from '../pages/constants/pages';
import { useCallback } from 'react';

export const useNavigateWithTracker = () => {
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();

  const navigateWithTracker = useCallback(
    (customName: Pages, to: To, options?: NavigateOptions) => {
      const name = customName + ' - Technician CoPilot';
      appInsights.trackPageView({ name });
      navigate(to, options);
    },
    [appInsights, navigate],
  );

  return navigateWithTracker;
};
