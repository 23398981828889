import { useState, useEffect, useRef, useCallback } from 'react';

export const useWindowSize = () => {
  const isClient = typeof window === 'object'; //Object represents browser window
  const lastWidth = useRef<number>(0);

  const getSize = useCallback(() => {
    return isClient ? window.innerWidth : 0;
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!isClient) return;

    const handleResize = () => {
      if (window?.innerWidth !== lastWidth.current) {
        const width = getSize();
        lastWidth.current = width ?? 0;
        setWindowSize(width);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const mobileCheck = (e: MediaQueryListEvent | MediaQueryList) => {
      if (e.matches) {
        requestAnimationFrame(() => setIsMobile(true));
      } else {
        requestAnimationFrame(() => setIsMobile(false));
      }
    };

    mediaQuery.addEventListener('change', mobileCheck);

    mobileCheck(mediaQuery);

    return () => mediaQuery.removeEventListener('change', mobileCheck);
  }, []);

  return { windowSize, isMobile };
};
